import React from 'react'
import styled from 'styled-components';
import { useState } from "react";
import "./styles/Tabs.css"
import { Card } from "react-bootstrap";

const Tabs = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="container">
            <div className="bloc-tabs">
                <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                >
                    Healthcare Providers
                </button>
                <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                >
                    Hospitals/Retail Pharmacy
                </button>
                <button
                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)}
                >
                    Pharmaceutical Wholesalers
                </button>
                <button
                    className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(4)}
                >
                    Pharmaceutical Distributors
                </button>
            </div>

            <div className="content-tabs">
                <div
                    className={toggleState === 1 ? "content  active-content" : "content"}
                >
                    <h2>Healthcare Providers</h2>
                    <CardsA>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Medication Availability</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>They will be able to get information on new and existing drugs available and registered in Kenya for different conditions.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Options</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Check brands/generics, where to find drugs, prices, search by categories based on pharmacological class and anatomical.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">CPD points</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>They will get CPD points through curated Continuous Medical Education, training, and webinars in the platform.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Read>
                            <a href="https://www.patameds.com/healthcare_workers" class="btn btn-primary" target="_blank" rel="noreferrer" >Learn More</a>
                        </Read>
                    </CardsA>
                </div>

                <div
                    className={toggleState === 2 ? "content  active-content" : "content"}
                >
                    <h2>Hospitals/Retail Pharmacy</h2>
                    <CardsA>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Convenience</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Ordering pharmaceutical products online from  wholesalers conveniently.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Data Driven Decisions</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Procurement data analysis (costs, quantities, suppliers) for better decision making in medication purchase.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Save Cost and Time</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Searching for products and comparing prices from different distributors.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Read>
                            <a href="https://www.patameds.com/retail_hospitals" class="btn btn-primary" target="_blank" rel="noreferrer" >Learn More</a>
                        </Read>
                    </CardsA>
                </div>

                <div
                    className={toggleState === 3 ? "content  active-content" : "content"}
                >
                    <h2>Pharmaceutical Wholesalers</h2>
                    <CardsA>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Clients</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Get online clients/orders from hospitals/retail pharmacies within the platform – increase revenue.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Data Driven Decisions</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Customer purchase data analysis for better decision making in marketing, customer satisfaction and inventory management.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Shorter Time To Market</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Augment medical representatives capability with analytics and engagement tools.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Convenience</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Ordering pharmaceutical products online from distributors conveniently.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Data Driven Decisions</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Procurement data analysis (costs, quantities, suppliers) for better decision making in medication purchase.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Save Cost and Time</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Searching for products and comparing prices from different distributors.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                    </CardsA>
                    <Read>
                        <a href="https://www.patameds.com/wholesaler" class="btn btn-primary" target="_blank" rel="noreferrer" >Learn More</a>
                    </Read>
                </div>
                <div
                    className={toggleState === 4 ? "content  active-content" : "content"}
                >
                    <h2>Pharmaceutical Distributors</h2>
                    <CardsA>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Clients</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Get online clients/orders from wholesalers within the platform – increase revenue.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Data Driven Decisions</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Customer purchase data/ wholesale purchase data analysis for better decision-making in marketing, customer satisfaction, and inventory management.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Shorter Time to Market</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Augment medical representatives capability with analytics and engagement tools.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Generate Demand</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Advertise on the platform to all Healthcare workers by promoting your products – decreasing time to market.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Expand Your Reach</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>Market through CMEs with CPDs to attract more healthcare workers.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                        </Cards>
                        <Cards>
                            <div className="card-body">
                                <h5 className="card-title">Save Cost and Time</h5>
                                <p className="card-text" css={`border-top: 1px solid #fff;`}>We send emails to our email list to market your special offers.</p>
                            </div>
                        </Cards>
                    </CardsA>
                    <Read>
                        <a href="https://www.patameds.com/distributor" class="btn btn-primary" target="_blank" rel="noreferrer" >Learn More</a>
                    </Read>
                </div>
            </div>
        </div>
    )
}

export default Tabs


const Cards = styled(Card)`
    min-width: 200px;
    width: 40%;    
    background: #03989E;
    color: #fff;  
    margin-top:1rem;
    margin-left:1rem;
`
const CardsA = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center; 
    
    @media screen and (min-width: 500px) {
        justify-content: space-between; 
    }
`
const Read = styled.div`
    margin-top: 1rem;
    margin-left:1rem;
   
`
