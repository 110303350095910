import React from 'react'
import styled from 'styled-components';
import { Button } from '../components/Button';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import logo from "../images/logo.svg"
import { GlobalStyle } from '../components/styles/GlobalStyles';
import SEO from '../components/seo';
import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";
import Tabs from './../components/Tabs';

const Patameds_LandingPage = () => {
    const data = useStaticQuery(graphql`
        query PatamedsLandingPageQuery {
            allLandingPagesJson {
            edges {
                node {
                alt7
                img7 {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                alt8
                img8 {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
            }
        }
      
    `)

    const getImage = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt7}
                        fluid={element.node.img7.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }

    const getImageSignup = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt8}
                        fluid={element.node.img8.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }


    return (
        <Layout>
            <Container>
                <SEO title="Patameds - Pharmaceutical B2B e-commerce platform in Kenya"
                    description="Easily buy medication online from verified pharmaceutical companies in Kenya. Discover products at better rates, transact and get them delivered. Learn more"
                    keywords="Pharmaceutical companies in kenya,Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain,List of pharmaceutical companies in kenya,Pharmaceutical wholesalers in Nairobi,Pharmaceutical distributors in kenya,Pharmaceutical distributors in Nairobi,companies looking for distributors in kenya,pharmaceutical importers in kenya, pharmacy pos"
                    image="https://www.tripleaimsoftware.com/static/b9d37b8f622c621c0f2b281da2d3721b/14b42/e-commerce.jpg"
                    siteUrl="https://www.tripleaimsoftware.com/patameds/"
                    canonical="https://www.tripleaimsoftware.com/patameds/"
                >
                </SEO>
                <GlobalStyle />
                <img src={logo} alt="logo" css={`width: 130px; height: 130px;position: absolute; top: -30px; left: 10px`} />
                <Hero>
                    <CtaWrapper>
                        <ContainerP>Save Time and Money</ContainerP>
                        <ContainerP2>Pharmaceutical marketplace enabling data-driven decision making in pharma supply chain</ContainerP2>
                        <Button as="button" primary="true" css={`width: 100%; height: 50px; background:#03989E;border:none`}><a css={`color: #fff`} href='https://www.patameds.com/' target="_blank" rel="noreferrer" >Get Started</a></Button>
                    </CtaWrapper>
                    <ImgWrapper>
                        {getImage(data)}
                    </ImgWrapper>
                </Hero>
                <ContainerP css={`text-align: center;`} >Patameds Value Proposition</ContainerP>
                <Intro>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">Providing information on medication availability and online ordering</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Enabling pharmacies/ hospitals to order medication from wholesalers,and wholesalers to order medication from pharmaceutical distributors.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">Enabling data-driven decision making</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Provision of data analytics dashboards to the stakeholders based on the data generated from the e-commerce platform.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                    <Cards>
                        <div className="card-body">
                            <h5 className="card-title">Enabling omnichannel marketing of pharmaceuticals</h5>
                            <p css={`border-top: 1px solid #fff;`} className="card-text">Enabling pharmaceutical distributors to market their products to healthcare providers within the platform.</p>
                            {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                        </div>
                    </Cards>
                </Intro>
                <Client>
                    <Tabs />
                </Client>
                <Hero>
                    <CtaWrapper>
                        <ContainerP2 css={`font-size: 1.5rem;font-weight: bold;`}>Join other healthcare providers in embracing technology with Patameds</ContainerP2>
                        <Button as="button" primary="true" css={`width: 100%; height: 50px;background:#03989E;border:none`}><a css={`color: #fff`} href='https://www.patameds.com/' target="_blank" rel="noreferrer">Sign up</a></Button>
                    </CtaWrapper>
                    <ImgWrapper>
                        {getImageSignup(data)}
                    </ImgWrapper>
                </Hero>
            </Container>
        </Layout>
    )
}

export default Patameds_LandingPage

const Container = styled.div`
    height: 100%;
    width: 100%;
    margin:0px;
    padding:0px;
`
const Hero = styled.div`
    display:flex;
    height: 80vh;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
`
const CtaWrapper = styled.div`
    margin-left: 1rem;
    padding:0rem 3rem;
    flex: 1 1 30em;
    
`
const ImgWrapper = styled.div`
    flex: 1 1 30em;
`
const Image = styled(Img)`
   max-width: 600px;
   margin-left: auto;
   margin-right: auto;
`
const ContainerP = styled.h2`
    font-size: clamp(1.5rem,2vw, 2rem);
    //text-align: center;
    color: #000;
    margin-bottom: 1rem;
`
const ContainerP2 = styled.p`
    font-size: clamp(1rem,2vw, 1.2rem);
    //text-align: center;
    color: #000;
    margin-bottom: 1rem;
`
const Intro = styled.div`
    width: 100%;
    height: 100%;
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
`
const Cards = styled(Card)`
    width: 17.5%;
    height: 400px;
    background: #03989E;
    color: #fff;  
    min-width:200px;
`
const Client = styled.div`
    width: 100%;
    height: 100%;
    //background: blue;    
`


